import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {ReturnMechanism} from '@pinecone-experience/integrations';
import {type ConnectionInfoData, MessageAction, sendMessage} from '@pinecone-experience/messages';
import {useCallback, useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {CONSOLE_URL} from '../../constants';
import {useHost} from '../Host/hostContext';
import {useIntegrationInfo} from '../Integrations/integrationInfo';

type FinishProps = {
  connectionInfo: ConnectionInfoData | null;
};

const styles = {
  container: {
    mt: -2.5,
  },
};

/**
 * Finish is the last page of the widget. It displays the API key that was generated, or, if there
 * is a valid host origin, provides it to the host directly.
 */
function Finish({connectionInfo}: FinishProps) {
  const {origin} = useHost();
  const integration = useIntegrationInfo();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      connectionInfo &&
      window.parent &&
      origin &&
      integration.return_mechanism === ReturnMechanism.WEB_MESSAGE
    ) {
      sendMessage(MessageAction.API_KEY, window.parent, origin, {key: connectionInfo.key});
    }
  }, [connectionInfo, origin, integration]);

  const reset = useCallback(() => navigate('/'), [navigate]);

  if (!connectionInfo) {
    return (
      <Box sx={styles.container}>
        <Typography>We encountered an unexpected issue. Please try again.</Typography>
        <Button onClick={reset} variant="contained" sx={{mt: 2.5}}>
          Retry connection
        </Button>
      </Box>
    );
  }

  return (
    <Box sx={styles.container}>
      <Typography>
        {integration.return_mechanism === ReturnMechanism.WEB_MESSAGE
          ? `You're all set! An API key has been generated and provided to ${integration.profile.name}.`
          : `You're all set! Copy and paste the API key value that was provided to you.`}
      </Typography>
      <Box sx={{height: 12}} />
      <Typography>
        You can manage your API keys in the{' '}
        <Link
          to={`${CONSOLE_URL}/organizations/${connectionInfo.organizationId}/projects/${connectionInfo.globalProjectId}/keys`}
          target="_blank"
        >
          Pinecone console
        </Link>
        .
      </Typography>
    </Box>
  );
}

export default Finish;
