import type {MessageAction, MessageCallback, MessageData, MessageResponder} from './types';

/** Sends an action message to the target window at the target origin. */
export function sendMessage<T extends MessageAction>(
  action: T,
  targetWindow: Window,
  targetOrigin: string | null,
  data?: MessageData<T>,
) {
  if (targetOrigin) targetWindow.postMessage({action, ...data}, {targetOrigin});
}

/** Sends an action message to the target window at any origin. */
export function broadcastMessage<T extends MessageAction>(
  action: T,
  targetWindow: Window,
  data?: MessageData<T>,
) {
  sendMessage(action, targetWindow, '*', data);
}

/**
 * Listens for action messages from the allowed origin and calls the callback upon receipt.
 * Returns a function that halts the listening.
 */
export function listenForMessage<T extends MessageAction>(
  action: T,
  isAllowedOrigin: (origin: string) => boolean,
  callback: MessageCallback<T>,
) {
  const listener = ({data, source, origin}: MessageEvent) => {
    if (!isAllowedOrigin(origin) || !source) return;
    if (data.action === action) {
      callback(data, source, origin);
    }
  };
  window.addEventListener('message', listener);
  return () => window.removeEventListener('message', listener);
}

/**
 * Listens for an action message from the allowed origin and calls the callback upon receipt. Only
 * listens once. Returns a function that halts the listening.
 */
export function listenForMessageOnce<T extends MessageAction>(
  action: T,
  isAllowedOrigin: (origin: string) => boolean,
  callback: (data: MessageData<T>, source: MessageEventSource, origin: string) => void,
) {
  const listener = ({data, source, origin}: MessageEvent) => {
    if (!isAllowedOrigin(origin) || !source) return;
    if (data.action === action) {
      window.removeEventListener('message', listener);
      callback(data, source, origin);
    }
  };
  window.addEventListener('message', listener);
  return () => window.removeEventListener('message', listener);
}

/**
 * Listens for action messages from the allowed origin and responds with a message of a different
 * action. Returns a function that halts the listening.
 */
export function respondToMessage<T extends MessageAction, W extends MessageAction>(
  toAction: T,
  withAction: W,
  isAllowedOrigin: (origin: string) => boolean,
  withData?: MessageResponder<T, W>,
) {
  return listenForMessage(toAction, isAllowedOrigin, (data, source, origin) => {
    const response = withData?.(data);
    source.postMessage({action: withAction, ...response}, {targetOrigin: origin});
  });
}

/**
 * Listens for an action message from the allowed origin and responds with a message of a different
 * action. Only responds once. Returns a function that halts the listening.
 */
export function respondToMessageOnce<T extends MessageAction, W extends MessageAction>(
  toAction: T,
  withAction: W,
  isAllowedOrigin: (origin: string) => boolean,
  withData?: MessageResponder<T, W>,
) {
  return listenForMessageOnce(toAction, isAllowedOrigin, (data, source, origin) => {
    const response = withData?.(data);
    source.postMessage({action: withAction, ...response}, {targetOrigin: origin});
  });
}
