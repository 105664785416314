export enum ReturnMechanism {
  WEB_MESSAGE = 'WebMessage',
  COPY_PASTE = 'CopyPaste',
}

export type Integration = {
  slug: string;
  profile: {
    name: string;
  };
  return_mechanism: ReturnMechanism;
  allowed_origins?: string[];
};
