import {alpha} from '@mui/material/styles';
import {type PaletteOptions, createTheme} from '@mui/material/styles';
import {PineconeColors} from '../colors';
import PineconeShadows from './PineconeShadows';
import common from './common';

const palette: PaletteOptions = {
  mode: 'dark',
  primary: {
    main: PineconeColors.DARK.PRIMARY_MAIN,
    dark: PineconeColors.DARK.PRIMARY_DARK,
    contrastText: PineconeColors.DARK.PRIMARY_CONTRAST_TEXT,
  },
  secondary: {
    main: PineconeColors.DARK.SECONDARY,
  },
  info: {
    main: PineconeColors.DARK.INFO,
  },
  error: {
    main: PineconeColors.DARK.ERROR,
  },
  warning: {
    main: PineconeColors.DARK.WARNING,
  },
  success: {
    main: PineconeColors.DARK.SUCCESS,
  },
  background: {
    default: PineconeColors.DARK.BACKGROUND,
    paper: PineconeColors.DARK.PAPER,
    surface: PineconeColors.DARK.SURFACE,
  },
  divider: PineconeColors.DARK.DIVIDER,
  action: {
    selected: alpha(PineconeColors.DARK.PRIMARY_MAIN, 0.08),
    hover: alpha(PineconeColors.DARK.PRIMARY_MAIN, 0.04),
  },
};

const DarkTheme = createTheme({
  palette,
  shadows: PineconeShadows.DARK,
  ...common,
});

export default DarkTheme;
