import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import {ThemeProvider} from '@mui/material/styles';
import {LightTheme} from '@pinecone-experience/theme';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import './styles/index.css';

const styles = {
  global: {
    body: {
      background: 'transparent',
      backgroundColor: 'rgba(0, 0, 0, 0)',
    },
  },
};

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={LightTheme}>
      <CssBaseline />
      <GlobalStyles styles={styles.global} />
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
