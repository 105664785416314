/* eslint-disable no-barrel-files/no-barrel-files */
import {type Integration} from './types';

export {type Integration, ReturnMechanism} from './types';
export {ReturnMechanismNames} from './constants';

export const IntegrationsRepo = (dashboardUrl: string) => {
  const getIntegration = async (integrationId: string) => {
    const res = await fetch(`${dashboardUrl}/connect/${integrationId}`);
    if (!res.ok) {
      if (res.status >= 500 && res.status < 600) {
        throw new Error('A management plane error has occured.');
      } else {
        const body = await res.json();
        throw new Error(body.message || body.error);
      }
    }
    const body = await res.json();
    return body as Integration;
  };

  const getIntegrationLogoUrl = (integrationId: string) => {
    return `${dashboardUrl}/connect/${integrationId}/logo`;
  };

  return {getIntegration, getIntegrationLogoUrl};
};

export const isAllowedOrigin = (integration: Integration, origin: string) => {
  return (
    integration.allowed_origins?.some((pattern) => {
      const escapeRegex = (str: string) => str.replace(/([.*+?^=!:${}()|[\]/\\])/g, '\\$1');
      return new RegExp(`^${pattern.split('*').map(escapeRegex).join('[^.]+')}$`).test(origin);
    }) ?? false
  );
};
