interface ThemePalette {
  PRIMARY_MAIN: string;
  PRIMARY_DARK: string;
  PRIMARY_LIGHT: string;
  PRIMARY_CONTRAST_TEXT: string;
  BACKGROUND: string;
  PAPER: string;
  SURFACE: string;
  DIVIDER: string;
  SECONDARY: string;
  ERROR: string;
  WARNING: string;
  SUCCESS: string;
  INFO: string;
}

const PineconeColors: Record<string, ThemePalette> = {
  LIGHT: {
    PRIMARY_MAIN: '#1B17F5',
    PRIMARY_DARK: '#030080',
    PRIMARY_LIGHT: '#3C72FF',
    PRIMARY_CONTRAST_TEXT: '#FFF',
    BACKGROUND: '#FBFBFC',
    PAPER: '#FFF',
    SURFACE: '#F1F5F8',
    DIVIDER: '#D8DDDF',
    SECONDARY: '#72788D',
    ERROR: '#E31957',
    WARNING: '#DDB15D',
    SUCCESS: '#15B077',
    INFO: '#1B17F5',
  },
  DARK: {
    PRIMARY_MAIN: '#94C1F2',
    PRIMARY_DARK: '#FFF',
    PRIMARY_LIGHT: '#94C1F2',
    PRIMARY_CONTRAST_TEXT: '#141424',
    BACKGROUND: '#141424',
    PAPER: '#212232',
    SURFACE: '#50516B',
    DIVIDER: '#3D3F59',
    SECONDARY: '#FFF',
    ERROR: '#E31957',
    WARNING: '#DDA15D',
    SUCCESS: '#15B077',
    INFO: '#73AEE5',
  },
};

export default PineconeColors;
