export enum MessageAction {
  ACK = 'ack',
  API_KEY = 'api_key',
  CANCEL = 'cancel',
  CONNECTION_INFO = 'connection_info',
  READY = 'ready',
}

/** Information about the API key the user has created. */
export type ApiKeyData = {
  /** The API key value. */
  key: string;
};

/** Information about the connection that the user has authorized. */
export type ConnectionInfoData = {
  /** The ID of the organization that was connected. */
  organizationId: string;
  /** The ID of the project that was connected. */
  globalProjectId: string;
  /** The API key value for making use of this connection. */
  key: string;
};

type ActionMap = {
  [MessageAction.API_KEY]: ApiKeyData;
  [MessageAction.CONNECTION_INFO]: ConnectionInfoData;
};

export type MessageData<T extends MessageAction> = T extends keyof ActionMap
  ? ActionMap[T]
  : undefined;

export type MessageCallback<T extends MessageAction> = (
  data: MessageData<T>,
  source: MessageEventSource,
  origin: string,
) => void;

export type MessageResponder<T extends MessageAction, W extends MessageAction> = (
  data: MessageData<T>,
) => MessageData<W>;
