import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import {type PropsWithChildren} from 'react';
import PineconeLogoDark from '../../assets/pinecone_full_logo_dark.svg';

type WidgetCardProps = PropsWithChildren<Record<never, never>>;

const styles = {
  card: {
    width: '500px',
    height: '182px',
    py: '24px',
    px: '24px',
    mx: 0,
    my: '24px',
    border: '1px solid #E0E0E0',
    display: 'grid',
    gridTemplateRows: '1fr 1fr',
    rowGap: '24px',
  },
  logo: {
    height: '30px',
    m: 0,
    p: 0,
  },
};

/** Provides a visual frame around the widget. */
function WidgetFrame({children}: WidgetCardProps) {
  return (
    <Paper sx={styles.card}>
      <Box src={PineconeLogoDark} alt="Pinecone" component="img" sx={styles.logo} />
      {children}
    </Paper>
  );
}

export default WidgetFrame;
