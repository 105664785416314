import Typography from '@mui/material/Typography';
import {type Integration, IntegrationsRepo} from '@pinecone-experience/integrations';
import LoadingContainer from '@pinecone-experience/timber/LoadingContainer';
import {type PropsWithChildren, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {DASHBOARD_URL} from '../../constants';
import {IntegrationInfoContext} from './integrationInfo';

type IntegrationInfoProviderProps = PropsWithChildren<{}>;

/**
 * Ensures that integration info has loaded before children are rendered. Children of an
 * IntegrationGuard can use the useIntegrationInfo hook to obtain the integration info.
 */
function IntegrationGuard({children}: IntegrationInfoProviderProps) {
  const {integrationId} = useParams<{integrationId: string}>();
  const [integration, setIntegration] = useState<Integration | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (integrationId) {
      setLoading(true);
      IntegrationsRepo(DASHBOARD_URL)
        .getIntegration(integrationId)
        .then((res) => {
          setIntegration(res);
          setLoading(false);
        });
    }
  }, [integrationId]);

  return (
    <LoadingContainer loading={loading}>
      {!integration && (
        <Typography color="error">Error: integration {integrationId} not found</Typography>
      )}
      {integration && !loading && (
        <IntegrationInfoContext.Provider value={integration}>
          {children}
        </IntegrationInfoContext.Provider>
      )}
    </LoadingContainer>
  );
}

export default IntegrationGuard;
