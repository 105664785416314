import {alpha} from '@mui/material/styles';
import {type PaletteOptions, createTheme} from '@mui/material/styles';

import {PineconeColors} from '../colors';
import PineconeShadows from './PineconeShadows';
import common from './common';

const palette: PaletteOptions = {
  mode: 'light',
  primary: {
    main: PineconeColors.LIGHT.PRIMARY_MAIN,
    dark: PineconeColors.LIGHT.PRIMARY_DARK,
    contrastText: PineconeColors.LIGHT.PRIMARY_CONTRAST_TEXT,
  },
  secondary: {
    main: PineconeColors.LIGHT.SECONDARY,
  },
  info: {
    main: PineconeColors.LIGHT.INFO,
  },
  error: {
    main: PineconeColors.LIGHT.ERROR,
  },
  warning: {
    main: PineconeColors.LIGHT.WARNING,
  },
  success: {
    main: PineconeColors.LIGHT.SUCCESS,
  },
  background: {
    default: PineconeColors.LIGHT.BACKGROUND,
    paper: PineconeColors.LIGHT.PAPER,
    surface: PineconeColors.LIGHT.SURFACE,
  },
  divider: PineconeColors.LIGHT.DIVIDER,
  action: {
    selected: alpha(PineconeColors.LIGHT.PRIMARY_MAIN, 0.08),
    hover: alpha(PineconeColors.LIGHT.PRIMARY_MAIN, 0.04),
  },
};

const LightTheme = createTheme({
  palette,
  shadows: PineconeShadows.LIGHT,
  ...common,
});

export default LightTheme;
