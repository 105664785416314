import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {ConnectPopup} from '@pinecone-database/connect';
import {ReturnMechanism} from '@pinecone-experience/integrations';
import {type ConnectionInfoData, MessageAction, sendMessage} from '@pinecone-experience/messages';
import {useCallback, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {useHost} from '../Host/hostContext';
import {useIntegrationInfo} from '../Integrations/integrationInfo';

type ConnectButtonProps = {
  setConnectionInfo: (data: ConnectionInfoData | null) => void;
};

const styles = {
  button: {
    height: 36,
    width: 'fit-content',
    m: 0,
    marginTop: '-10px',
  },
};

/** ConnectButton is a button that opens the Pinecone console's grant page in a new window. */
function ConnectButton({setConnectionInfo}: ConnectButtonProps) {
  const navigate = useNavigate();
  const integration = useIntegrationInfo();
  const {origin} = useHost();

  const onConnect = useCallback(
    (data: ConnectionInfoData) => {
      setConnectionInfo(data);
      navigate('finish');
    },
    [navigate, setConnectionInfo],
  );

  const onCancel = useCallback(() => {
    if (window.parent && origin && integration.return_mechanism === ReturnMechanism.WEB_MESSAGE) {
      sendMessage(MessageAction.CANCEL, window.parent, origin);
    }
  }, [origin, integration.return_mechanism]);

  const popup = useMemo(
    () => ConnectPopup({onConnect, onCancel, integrationId: integration.slug}),
    [integration.slug, onCancel, onConnect],
  );

  return (
    <>
      <Typography>Connect with Pinecone to generate your API key.</Typography>
      <Button onClick={popup.open} sx={styles.button} variant="contained">
        Connect to Pinecone
      </Button>
    </>
  );
}

export default ConnectButton;
